<template>
  <!--我 组件-->
  <div id="self">
    <div class="weui-tab__content" style="display: block;">
      <div class="weui-cells" style="margin-top:0rem;padding-top:2rem;padding-bottom:0rem">
        <router-link to="/self/profile" style="padding-bottom:0rem" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img :src="userInfo.avatar" alt="" class="self-header">
          </div>
          <div class="weui-cell__bd">
            <h4 class="self-nickname" style="font-size:20px;font-weight: 550;">{{ userInfo.nickname }}</h4>

            <p class="self-wxid" style="font-size:18px;color:#999">微信号: {{ userInfo.wxcode }}</p>
             <!-- <p class="self-wxid"><img style="width:70px;height:40px;" src="/images/zhuantai.jpg"></p>-->
          </div>
          
          <div class="weui-cell__ft">
            <img src="/images/chat-info-qr.png">
          </div>
        </router-link>
    <p class="self-wxid"><img style="width:90px;height:50px;margin-left:6rem" src="/images/zhuantai.jpg"></p>
      </div>
      <div class="weui-cells" style="font-size:18px">
        <router-link to="/self/service" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/fuwu.png">
          </div>
          <div class="weui-cell__bd">
            <p>服务</p>
          </div>
          <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
      </div>
      <div class="weui-cells" style="font-size:18px">

        <router-link to="/self/album" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/shoucan.png">
          </div>
          <div class="weui-cell__bd">
            <p>收藏</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
        <router-link to="/self/album" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/pengyou.png">
          </div>
          <div class="weui-cell__bd">
            <p>朋友圈</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>

        <router-link to="/self/album" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/kabao.png">
          </div>
          <div class="weui-cell__bd">
            <p>卡包</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
        <router-link to="/self/album" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/shipin.png">
          </div>
          <div class="weui-cell__bd">
            <p>视频号</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
        <router-link to="/self/album" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/biaoqing.png">
          </div>
          <div class="weui-cell__bd">
            <p>表情</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
      </div>
  
      <div class="weui-cells" style="font-size:18px">
        <router-link to="/self/settings" class="weui-cell weui-cell_access">
          <div class="weui-cell__hd">
            <img src="/images/shezhi.png">
          </div>
          <div class="weui-cell__bd">
            <p>设置</p>
          </div>
                    <div class="weui-cell__hd">
            <van-icon color="#999" name="arrow" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

  export default {
    mixins: [window.mixin],
    data() {
      return {
        "pageName": "我"
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      })
    },
    mounted() {
      this.$store.commit("toggleTipsStatus", -1)
    },
    activated() {
      this.$store.commit("toggleTipsStatus", -1)
    }
  }
</script>
<style lang="less">
  @import "../../assets/less/self.less";
  
  .weui-cells:after, .weui-cells:before {
    content: none;
    position: absolute;
    left: 0;
    right: 0;
    height: 0px;
    color: #fff;
}
.weui-cell:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 1px;
    border-top: 1px solid #d9d9d9;
    color: #d9d9d9;
    transform-origin: 0 0;
    transform: scaleY(.3);
    left: 54px;
}
</style>